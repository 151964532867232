.container {
    background-color: #fff;
    padding: 30px;
}

.formWrapper {
    padding-bottom: 20px;
}

.functions {
    padding: 10px 0;
    display: flex;
    width: 100%;
}

.checkboxList {
    max-height: 150px;
    overflow: hidden;
    overflow-y: scroll;
    margin: 0 -15px;
    display: flex;
    flex-direction: column;
}