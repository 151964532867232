.container {
    height: 120px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    &.border {
        border-bottom: 1px solid #CCCCCC;
    }
}

.title {
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    line-height: 30px;
    margin-bottom: 15px;
}

.subtitle {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    line-height: 14px;
}

.titleGroup {
    display: flex;
    flex-direction: column;
}

.buttons {
    display: flex;
    margin-left: auto;
}

.buttons button {
    margin-left: 10px;
}