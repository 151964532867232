
.itemPanelTitle {
    color: #636569;
    font-weight: 500;
    font-size: 14px;
}

.itemPanelContent {
    display: flex;
    img {
        width: 85px;
        height: auto;
        display: block;
        min-width: 85px;
        align-self: flex-start;
    }
    .itemContent {
        padding-left: 50px;
        flex: 1;
    }
}
.iconMove {
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: move;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.moveBox {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    button {
        margin: 0 5px;
    }
}

.imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    &.imageWrapperMiddle {
        margin-left: -135px;
    }
    .imageItem {
        margin: 0 15px;
    }
    .imageItemText {
        width: 192px;
        margin-left: 0;
        align-self: flex-start;
    }
    .itemTextarea {
        flex: 1;
        padding-left: 20px;
    }
}