.container {
    padding: 0 30px;
    h3 {
        margin-bottom: 0;
    }
}
.itemGroup {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    align-items: center;
    border-bottom: 1px solid #EAEAEA;
    h6 {
        font-weight: 400;
        font-size: 14px;
        width: 70px;
    }
    .itemList {
        display: flex;
        flex-wrap: wrap;
        .item {
            cursor: pointer;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            color: #636569;
            font-size: 12px;
            margin-left: 20px;
            &.itemActive {
                img {
                    border-color: #000;
                }
            }
            img {
                border: 2px solid transparent;
                width: 85px;
                display: block;
            }
            span {
                padding-top: 10px;
            }
        }
    }
}