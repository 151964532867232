body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-layout-background {
  background-color: #fff !important;
}
.content-body {
  padding: 20px 0;
}

.layout-content {
  margin: 0 24px;
  min-height: 80vh !important;
}

.ant-pro-table-search {
  margin-bottom: 16px;
  padding: 24px 24px 24px;
  background: #fff;
}

.divider-no-gap:before {
  width: 0;
}
.divider-no-gap .ant-divider-inner-text {
  padding: 0;
}

.ant-modal .ant-modal-content {
  padding: 0;
}

.ant-modal .ant-modal-content .ant-modal-header {
  background-color: #000;
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.ant-modal .ant-modal-content .ant-modal-close {
  color: #fff;
}

.ant-modal-custom .ant-modal-content .ant-modal-footer {
  background-color: #EAEAEA;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 60px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-modal .ant-modal-content .ant-modal-body {
  padding: 30px;
}

.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
}

.wrapper-content {
  padding: 0 30px 30px;
}