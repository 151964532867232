.logo {
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #000;
    line-height: 20px;
    padding: 20px 0 0 20px;
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 1;
    justify-self: flex-end;
}

.logo.collapsed {
    font-size: 12px;
    text-align: center;
    padding: 20px 0;
}

.collapsedButton {
    cursor: pointer;
}

.dropdownItem {
    margin-left: auto;
}

.dropdownButton {
    color: #000;
    font-weight: 600;
}

.header {
    align-items: center;
    font-weight: 600;
    padding: 0 0 0 20px;
    display: flex;
    border-bottom: 1px solid #CCCCCC;
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
}

.sider {
    display: flex;
    flex-direction: column;
    background-color: #000;
}

.menuBox {
    overflow: auto;
    position: sticky;
    top: 60px;
}

.divider {
    padding: 0;
    margin: 10px 0;
    background-color: rgba(255,255,255,0.2);
}

.weather {
    padding-left: 10px;
}

@media only screen and (max-width: 800px) {
    .weather {
        display: none;
    }
}