body, html, :global(#root) {
    height: 100%;
    background-color: #c9c9c9;
}
.container {
    background-color: #c9c9c9;
    background-size: 100%;
    width: 100%;
    height: 100%;
}

.loginForm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 40px 35px 25px;
    font-size: 14px;
    border-radius: 15px;
    width: 400px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.title {
    margin-bottom: 10px;
    line-height: 45px;
    font-size: 24px;
    color: #1f2226;
    font-weight: 600;
}

.logo {
    margin: 0 auto 120px;
    text-align: left;
    display: block;
    font-weight: bold;
    font-size: 30px;
}

@media (max-width: 414px) {
    .loginForm {
        width: 94vw;
    }
}