.imageCropContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.uploadListInline {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
:global {
    .ant-upload-list {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.previewImageVertical {
    align-self: flex-start;
    position: relative;
    max-height: 192px;

    & > img {
        // max-height: 102px;
        display: block;
    }
}

.previewImage {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 5px;
}

.previewImageMinHorizontal {
    background-size: contain;
}

.circle :global(.ant-upload.ant-upload-select-picture-card) {
    border-radius: 50%;
    overflow: hidden;
}

.uploadWrapper {
    display: flex;
    flex-direction: row;
    :global(.ant-upload-picture-card-wrapper) {
        height: 100%;
    }

    &.uploadWrapperVertical {
        flex-direction: column;
        width: 108px;
        height: 192px;
        background-color: #d9d9d9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .plusOutIcon {
            color: #fff;
        }

        :global(.ant-upload) {
            background-color: transparent !important;
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
        }

        .previewBox {
            flex-direction: column;
            button {
                margin: 5px 0 !important;
            }
        }
    }

    &.uploadWrapperHorizontal {
        width: 192px;
        height: 108px;
        background-color: #d9d9d9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .plusOutIcon {
            color: #fff;
        }

        :global(.ant-upload) {
            background-color: transparent !important;
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
        }
    }


    &.uploadWrapperMinHorizontal {
        width: 100px;
        height: 32px;
        background-color: #d9d9d9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .plusOutIcon {
            color: #fff;
        }

        :global(.ant-upload) {
            background-color: transparent !important;
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
        }
    }

    .plusOutIcon {
        font-size: 30px;
        font-weight: 500;
        color: #ccc;
    }

    .previewBox {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        > button:nth-child(2) {
            margin-left: 10px;
        }
        opacity: 0;
    }

    .previewImage:hover {
        .previewBox {
            opacity: 1;
        }
    }
}
