.header {
    background-color: #FFF8B8;
    height: 44px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CCCCCC;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 600;
}

.content {
    padding: 30px;
    padding-bottom: 100px;
}

.panelTitle {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.collapseCustom {
    border-color: #ccc;
    background-color: #ccc;
}

.itemPanelCon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.itemPanelTitle {
    color: #636569;
    font-weight: 500;
    font-size: 14px;
}

.content :global(.ant-collapse-item) {
    border-color: #ccc;
}

.footer {
    background-color: #EAEAEA;
    border-top: 1px solid #CCCCCC;
    height: 60px;
    display: flex;
    position: fixed;
    left: 200px;
    right: 0;
    bottom: 0;
    align-items: center;
    padding: 0 40px;
    z-index: 2;

    button {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}