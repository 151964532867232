.card {
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    font-weight: 600;
    margin-bottom: 10px;

    .title {
        font-size: 14px;
        padding-bottom: 20px;
        margin-bottom: 0;
    }

    .content {
        font-size: 24px;
        display: flex;
        align-items: center;
        color: #000;
    }

    .value {
        font-size: 60px;
        line-height: 1;
    }

    .unit {
        font-size: 20px;
        position: relative;
        align-self: self-start;
        padding-left: 8px;
    }

    .extra {
        margin-left: auto;
        font-size: 18px;
        font-weight: normal;
    }
}