.container {
    border: 1px solid #ccc;
    border-radius: 5px;
    // overflow: hidden;
}

// &:global(.w-e-hover-bar .w-e-bar-item:nth-child(-n+8):nth-child(n+3)) {
//     display: none;
// }

.toolBar {
    border-bottom: 1px solid #ccc;
    height: 50px;
    display: flex;
    align-items: center;
}